/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import classNames from 'classnames';
import React from 'react';
import { ImageModel } from '@type/Image.model';
import {
  injectSheet,
  PictureBuilder,
  AudioEmbedPlayer,
  VideoEmbedPlayer,
  LinkOverlay,
  IFrame,
  Typography,
  SocialEmbed,
  TextFrame,
  List,
  Quote,
  SliderGallery,
  ChartList,
  ArticleComposite,
  getAppRoute,
  ECommerceAuctionWidget
} from '@imports';

import styles from './ContentfulRichText.styles';

export enum TypeOfContentfulRichText {
  VIDEO = 'video',
  IFRAME = 'iFrame',
  SOCIAL_EMBED = 'socialEmbed',
  TEXT_FRAME = 'textFrame',
  LINK_LIST = 'linkList',
  QUOTE = 'quote',
  SLIDER = 'slider',
  CHART_LIST = 'chartlist',
  AUDIO_EMBED = 'audioEmbed',
  COMPOSITE = 'composite',
  COMPONENT_SECTION = 'componentSection'
}

type ContentfulRichTextProps = {
  classes: any;
  props: any;
  data: {
    document: any;
    typographyVariant?: string;
    typographyFontWeight?: string;
    typographyColor?: string;
  };
  fallbackImage: ImageModel;
};

const ContentfulRichText: React.FC<ContentfulRichTextProps> = ({
  classes,
  data,
  ...props
}) => {
  let fullWidth = false;
  const appRoute = getAppRoute();
  const { typographyVariant, typographyFontWeight, typographyColor, document } =
    data;
  const parseOptions = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography
          variant={typographyVariant}
          fontWeight={typographyFontWeight}
          color={typographyColor}
          component="div"
          data-testid="h1"
        >
          <h1>{children}</h1>
        </Typography>
      ),

      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography
          variant={typographyVariant}
          fontWeight={typographyFontWeight}
          color={typographyColor}
          component="div"
          data-testid="h2"
        >
          <h2>{children}</h2>
        </Typography>
      ),

      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography
          variant="headerLarge"
          fontWeight={typographyFontWeight}
          color={typographyColor}
          component="h3"
          data-testid="h3"
        >
          {children}
        </Typography>
      ),

      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography
          variant={typographyVariant}
          fontWeight={typographyFontWeight}
          color={typographyColor}
          component="div"
          data-testid="h4"
        >
          <h4>{children}</h4>
        </Typography>
      ),

      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography
          variant={typographyVariant}
          fontWeight={typographyFontWeight}
          color={typographyColor}
          component="div"
          data-testid="h5"
        >
          <h5>{children}</h5>
        </Typography>
      ),

      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography
          variant={typographyVariant}
          fontWeight={typographyFontWeight}
          color={typographyColor}
          component="div"
          data-testid="h6"
        >
          <h6>{children}</h6>
        </Typography>
      ),

      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography
          variant={typographyVariant}
          fontWeight={typographyFontWeight}
          color={typographyColor}
          component="p"
          data-testid="paragraph"
        >
          {children}
        </Typography>
      ),

      [INLINES.HYPERLINK]: (node, children) => (
        // UTAG CHANGE - data-utag-label added
        <a
          href={node.data.uri}
          target="_blank"
          rel="noopener noreferrer"
          data-utag-label="inline-hyperlink"
        >
          {children}
        </a>
      ),

      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        // UTAG CHANGE - data-utag-label added
        <LinkOverlay
          content={node.data.target}
          variant="wrapper"
          data-utag-label="entry-hyperlink"
        >
          {children}
        </LinkOverlay>
      ),

      [BLOCKS.OL_LIST]: (node) => {
        const liChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => children,
            [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>
          }
        });

        return (
          <Typography
            variant={typographyVariant}
            color={typographyColor}
            component="div"
          >
            {liChildren}
          </Typography>
        );
      },

      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const fields = node.data.target.fields;
        const file = fields?.file || {};
        const title = fields?.title || '';
        const { url, contentType } = file;
        const imageCaption = fields?.description || '';

        if (!url) {
          return null;
        }

        if (file.details?.image) {
          let { width, height } = file.details.image;
          if (width && height && width / height <= 0.75) {
            width = 760;
            height = null;
          } else {
            width = 960;
            height = 540;
          }
          return (
            <div className={classes.imageWrapper}>
              <PictureBuilder
                imageData={{
                  url,
                  alt: title,
                  contentType,
                  imageSet: [
                    {
                      width,
                      height
                    }
                  ]
                }}
                className={classes.image}
              />
              {imageCaption && (
                <Typography
                  variant="caption"
                  className={classes.imageCaption}
                  data-testid="RTE-image-caption"
                >
                  {imageCaption}
                </Typography>
              )}
            </div>
          );
        }

        return <VideoEmbedPlayer video={file} />;
      },

      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        let contentComponent;
        const target = node.data?.target || {};
        const { fields, sys } = target;
        const id = sys?.contentType?.sys.id;
        if (sys?.contentType) {
          switch (id) {
            case TypeOfContentfulRichText.VIDEO:
              fullWidth = true;
              contentComponent = <VideoEmbedPlayer video={fields} />;
              break;
            case TypeOfContentfulRichText.IFRAME:
              contentComponent = (
                <IFrame content={fields} path={process.env.NEXT_PUBLIC_PATH} />
              );
              break;
            case TypeOfContentfulRichText.SOCIAL_EMBED:
              contentComponent = (
                <SocialEmbed {...fields} path={process.env.NEXT_PUBLIC_PATH} />
              );
              break;
            case TypeOfContentfulRichText.COMPOSITE:
              const { layout } = target?.fields;
              if (layout === 'image_left' || layout === 'image_right') {
                fullWidth = true;
              }

              contentComponent = (
                <ArticleComposite
                  content={target}
                  renderRte={(dataText) => (
                    <ContentfulRichText {...props} classes data={dataText} />
                  )}
                />
              );
              break;
            case TypeOfContentfulRichText.TEXT_FRAME:
              contentComponent = (
                <TextFrame
                  content={target}
                  renderRte={(dataTF) => (
                    <ContentfulRichText {...props} classes data={dataTF} />
                  )}
                />
              );
              break;
            case TypeOfContentfulRichText.LINK_LIST:
              if (fields?.items?.length > 0 && !appRoute) {
                const { items, title, showTitle } = fields;
                contentComponent = (
                  <List
                    variant="article"
                    title={title}
                    items={items}
                    showTitle={showTitle}
                    renderAudioEmbed={(item, variant, type) => (
                      <AudioEmbedPlayer
                        fields={item.fields}
                        item={item}
                        content={item}
                        variant={variant}
                        type={type}
                      />
                    )}
                  />
                );
              }
              break;
            case TypeOfContentfulRichText.AUDIO_EMBED:
              fullWidth = true;
              if (fields.omnyStudioType === 'single') {
                contentComponent = (
                  <AudioEmbedPlayer item={target} {...props} />
                );
              }
              break;
            case TypeOfContentfulRichText.QUOTE:
              contentComponent = <Quote content={fields} />;
              break;
            case TypeOfContentfulRichText.SLIDER:
              fullWidth = true;
              contentComponent = <SliderGallery item={target} />;
              break;
            case TypeOfContentfulRichText.CHART_LIST:
              contentComponent = (
                <ChartList source={fields.source.fields.file.url} article />
              );
              break;
            case TypeOfContentfulRichText.COMPONENT_SECTION:
              if (fields?.component === 'auction_widget') {
                fullWidth = true;
                contentComponent = (
                  <ECommerceAuctionWidget content={{ fields }} />
                );
              } else {
                return null;
              }
              break;
            default:
              contentComponent = null;
          }
        }
        const articleContentClasses = classNames(classes.rteContentHolder, {
          [classes.rteFullWidth]: fullWidth
        });

        return (
          <div className={articleContentClasses} data-testid="contentAndBanner">
            {contentComponent}
          </div>
        );
      }
    }
  };
  return <>{documentToReactComponents(document, parseOptions)}</>;
};

export default injectSheet(styles)(ContentfulRichText);
